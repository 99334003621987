import { isEmpty } from 'lodash-es';
import qs from 'query-string';

const apiUrl = "https://doctor.vcom.ru";
// const apiUrl = "https://spec.vitaclinica.ru";

const types = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const getQueryString = (obj) => {
  const queryObject = {};

  // eslint-disable-next-line array-callback-return
  Object.entries(obj).map(([key, value]) => {
    if (value && value !== null) {
      queryObject[key] = value;
    }
  });

  return !isEmpty(obj) ? `?${qs.stringify(obj, {arrayFormat: 'repeat'})}` : '';
};

const fetchWrapper = async (uri, type, body, headers = {
  "Content-Type": "application/json;charset=utf-8",
}, isFile = false) => {
  const responseOptions = {
    method: type ? type : types.GET,
    credentials: "include",
    headers,
  };
  if ((type === "POST" || type === "PUT") && body) {
    responseOptions.body = isFile ? body : JSON.stringify(body);
  }
  return await fetch(apiUrl + uri, responseOptions);
};

export const whoami = async () => {
  return await (await fetchWrapper("/whoami")).json();
};

export const login = async (login, password) => {
  return await (
    await fetchWrapper("/auth/login", types.POST, {
      login,
      password,
      remember: true,
    })
  ).json();
};

export const logout = async () => {
  return await (
    await fetchWrapper("/auth/logout", types.GET)
  ).json();
};

export const getFAQ = async (id) => {
  return await (
    await fetchWrapper(`/admin/faq${id ? `?id=${id}` : ''}`, types.GET)
  ).json();
};

export const addFAQ = async (name, answer, order) => {
  return await (
    await fetchWrapper("/admin/faq", types.POST, {
      name,
      answer,
      order,
    })
  ).json();
};

export const editFAQ = async (id, name, answer, order) => {
  return await (
    await fetchWrapper("/admin/faq", types.PUT, {
      id,
      name,
      answer,
      order,
    })
  ).json();
};

export const deleteFAQ = async (id) => {
  return await (
    await fetchWrapper(`/admin/faq${id ? `?id=${id}` : ''}`, types.DELETE)
  ).json();
};


export const getBanners = async (id) => {
  return await (
    await fetchWrapper(`/admin/banners${id ? `?id=${id}` : ''}`, types.GET)
  ).json();
};


export const addBanner = async (file, title, subtitle, url, cities, begin, end, isLoyaltyBanner, format) => {
  return await (
    await fetchWrapper(`/admin/banners${getQueryString({title, subtitle, url, cities, begin, end, isLoyaltyBanner, format})}`, types.PUT, file, 
    {
      'Content-Type': file.type,
      'Content-Length': file.size,
    }, true)
  ).json();
};

export const editBanner = async (id, file, title, subtitle, url, cities, begin, end, isLoyaltyBanner, format) => {
  return await (
    await fetchWrapper(`/admin/banners${getQueryString({id, title, subtitle, url, cities, begin, end, isLoyaltyBanner, format})}`, types.POST, file || null,
    file ? {
      'Content-Type': file.type,
      'Content-Length': file.size,
    } : {}, Boolean(file))
  ).json();
};

export const deleteBanner = async (id) => {
  return await (
    await fetchWrapper(`/admin/banners${id ? `?id=${id}` : ''}`, types.DELETE)
  ).json();
};


export const getDoctors = async () => {
  return await (
    await fetchWrapper('/specialists', types.GET)
  ).json();
};


export const getDoctor = async (id) => {
  return await (
    await fetchWrapper(`/admin/specialists${id ? `?id=${id}` : ''}`, types.GET)
  ).json();
};

export const addDoctor = async (specialist, title, url) => {
  return await (
    await fetchWrapper("/admin/specialists", types.PUT, {
      specialist,
      title,
      url,
    })
  ).json();
};

export const editDoctor = async (id, specialist, title, url) => {
  return await (
    await fetchWrapper("/admin/specialists", types.POST, {
      id, 
      specialist,
      title,
      url,
    })
  ).json();
};


export const deleteDoctor = async (id) => {
  return await (
    await fetchWrapper(`/admin/specialists${id ? `?id=${id}` : ''}`, types.DELETE)
  ).json();
};
